/* eslint class-methods-use-this: "off" */

import axios, { RawAxiosRequestConfig } from "axios";
import Gleap from "gleap";
import {
  clearOldToken,
  clearStorage,
  getRefreshToken,
  getToken,
  setCookie,
} from "../utils/general";
import { store } from "../Store/Store";
import { logout } from "../Store/Slices/AuthSlice";
import { setLoader, setNotificationCount } from "../Store/Slices/MasterSlice";
import { setDetailsTabInitialState } from "../Store/Slices/DetailsTabsSlice";

let interceptor: Interceptors | null = null;
const baseURL = process.env.NEXT_PUBLIC_API_URL;
const baseCubeURL = process.env.NEXT_PUBLIC_CUBE_URL;

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const setInitialState = async () => {
  Gleap.clearIdentity();
  clearStorage();
  clearOldToken();
  store.dispatch(setNotificationCount(0));
  store.dispatch(logout());
  sessionStorage.clear();
  localStorage.clear();
  store.dispatch(setDetailsTabInitialState());
  // await wait(100);
  // window.location.href = "/";
};

export const logoutUser = async () => {
  store.dispatch(setLoader(true));
  try {
    if (!getRefreshToken()) {
      throw new Error("Token Expired");
    }
    const res = await axios.post(
      `${baseURL && baseURL}/account/logout`,
      {
        token: getRefreshToken(),
      },
      {
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      },
    );
    if (res.data.status.code === 200 || res.data.status.code === 201) {
      setInitialState();
      // store.dispatch(setLoader(false));
    }
  } catch (err) {
    setInitialState();
    // store.dispatch(setLoader(false));
  }
  // store.dispatch(setLoader(false));
};

class Interceptors {
  HTTP = axios.create({
    headers: {
      authorization: `Bearer ${getToken()}`,
    },
  });

  prevResponse = null;

  constructor() {
    // Add a request interceptor
    this.HTTP.interceptors.request.use(
      async (config) => {
        const token = getToken();
        if (token) {
          // eslint-disable-next-line no-param-reassign
          config.headers.authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );
    // Add a response interceptor
    this.HTTP.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 || error?.response?.data?.tag === "INVALID_IP") {
          await logoutUser();
        } else if (
          (error?.response?.status === 401 || error?.response?.status === 403) &&
          !originalRequest?.headers?.["x-is-refresh"] &&
          // eslint-disable-next-line no-underscore-dangle
          !originalRequest._retry
          // && !isRefreshing
        ) {
          if (isRefreshing) {
            // If I'm refreshing the token I send request to a queue
            return new Promise((resolve, reject) => {
              failedQueue.push({ resolve, reject });
            })
              .then(() => {
                originalRequest.headers.authorization = getToken();
                return this.HTTP(originalRequest);
              })
              .catch((err) => err);
          }
          // eslint-disable-next-line no-underscore-dangle
          originalRequest._retry = true;
          isRefreshing = true;

          try {
            const rToken = getRefreshToken();
            const resp = await axios.post(
              `${baseURL && baseURL}/account/refresh-token`,
              {
                token: rToken,
              },
              {
                headers: {
                  "x-is-refresh": true,
                },
              },
            );
            if (resp.data.status.code === 200 || resp.data.status.code === 201) {
              const token = resp?.data?.data?.token;
              const refreshToken = resp?.data?.data?.refreshToken;
              setCookie(token, refreshToken);

              this.HTTP.defaults.headers.common.authorization = `Bearer ${token}`;
              originalRequest.headers.authorization = `Bearer ${token}`;
              isRefreshing = false;
              processQueue(null, token);
              return this.HTTP(originalRequest);
            }
          } catch (err) {
            isRefreshing = false;
            await logoutUser();
          }
        }
        return Promise.reject(error);
      },
    );
  }

  get(url: string | number, config?: RawAxiosRequestConfig<any> | undefined) {
    return this.HTTP.get(`${baseURL && baseURL}${url}`, { ...config });
  }

  getFile(url: string | number) {
    return this.HTTP.get(`${baseURL && baseURL}${url}`, {
      responseType: "blob",
      headers: {
        "Access-Control-Expose-Headers": "*",
      },
    });
  }

  post(url: string | number, data: any, config?: RawAxiosRequestConfig<any> | undefined) {
    return this.HTTP.post(`${baseURL && baseURL}${url}`, data, { ...config });
  }

  put(url: string | number, data: any, config?: RawAxiosRequestConfig<any> | undefined) {
    return this.HTTP.put(`${baseURL && baseURL}${url}`, data, { ...config });
  }

  deleteWithData(url: string | number, data: any) {
    return this.HTTP.delete(`${baseURL && baseURL}${url}`, { data });
  }

  delete(url: string | number, config?: RawAxiosRequestConfig<any> | undefined) {
    return this.HTTP.delete(`${baseURL && baseURL}${url}`, { ...config });
  }

  patch(url: string | number, data: any, config?: RawAxiosRequestConfig<any> | undefined) {
    return this.HTTP.patch(`${baseURL && baseURL}${url}`, data, { ...config });
  }

  getParams(url: string | number, params: any) {
    return axios(`${baseURL && baseURL}${url}`, {
      params: { ...params },
    });
  }

  // getCube(url: string | number, params: any) {
  //   return axios(`${baseCubeURL && baseCubeURL}${url}`, {
  //     params: { ...params },
  //   });
  // }

  getCube(url: string | number, config?: RawAxiosRequestConfig<any> | undefined) {
    return this.HTTP.get(`${baseCubeURL && baseCubeURL}${url}`, { ...config });
  }
}

export const interceptorFunction = () => {
  if (interceptor === null) {
    interceptor = new Interceptors();
  }
  return interceptor;
};
export default Interceptors;
